.stripes-bg {
  background: repeating-linear-gradient(
    145deg,
    #fff 0,
    #fff 10px,
    var(--chakra-colors-gray-50) 10px,
    var(--chakra-colors-gray-50) 20px
  ) !important;
}

.focus-none {
  outline: none;
}
